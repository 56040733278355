// Here you can add other styles

$theme-colors: (
  "primary": #026DE1,
);

.react-paginate {
  display: flex;
  padding-right: 10px;
  float: right;
}

.react-paginate-pagenum {
  margin: 0 10px;
  cursor: pointer;
  color: white;
}

.react-paginate-activepage {
  color: black;
}

ul {
  list-style-type: none;
}

a{
  color: white;
  text-decoration: underline;
  &:hover{
    color: white;
    text-decoration: none;
  }
}

.cl-white{
  color: white;
}

.bg-dark{
  background: #303c54;
  color: white !important;
}

.ck-editor__editable {
  min-height: 200px;
}